/* eslint-disable no-prototype-builtins */
import React, { useEffect } from 'react';
import { Box, Grid, Typography, useMediaQuery } from '@material-ui/core';
import LoginForm from 'forms/LoginForm';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { useAuth } from 'contexts/auth';
import DAF from 'images/DAF-branco.svg';
import Centered from 'components/Centered/Centered';
import WhatsappButton from 'components/WhatsappButton/WhatsappButton';

function Login() {
  const history = useHistory();
  const { user, signIn } = useAuth();
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));

  useEffect(() => {
    if (user.hasOwnProperty('novaSenha') && user.novaSenha == false) {
      history.push('/nova-senha');
    }

    if (user.hasOwnProperty('novaSenha') && user.novaSenha == true) {
      history.push('/');
    }
  }, [user]);

  const onSubmit = async values => {
    try {
      await signIn(values);
    } catch (error) {
      toast.error(
        'Não foi possível realizar o login, confira as credenciais informadas.',
      );
    }
  };

  if (isMobile) {
    return (
      <Box
        style={{ height: '100%', backgroundColor: '#014a94', color: '#fff' }}
      >
        <Centered column>
          <Box mb={7} mt={7} textAlign={'center'}>
            <img
              style={{ width: '100%', maxWidth: '288px' }}
              src={DAF}
              alt="Logo da DAF"
            />
          </Box>
          <Typography
            style={{
              fontSize: '1.5rem',
              textAlign: 'justify',
              marginBottom: '2rem',
            }}
          >
            Prova indisponível para acesso via dispositivo móvel, conforme
            regulamento:
          </Typography>
          <Typography
            style={{
              fontSize: '1.5rem',
              textAlign: 'justify',
              marginBottom: '2rem',
            }}
          >
            &quot;O participante deverá acessar as provas através de um
            computador (desktop ou laptop). Não será possível acessar através de
            dispositivos móveis.&quot;
          </Typography>
          <Typography
            style={{
              fontSize: '1.5rem',
              textAlign: 'justify',
              marginBottom: '2rem',
            }}
          >
            <a
              href="https://firebasestorage.googleapis.com/v0/b/questionario-daf.appspot.com/o/20233006%20-%20Regulamento%20-%20Te%CC%81cnico%20do%20Ano%20DAF%202023.pdf?alt=media&token=200b4901-4223-4a24-8414-ccde23588020"
              target="_blank"
              rel="noreferrer"
              style={{ fontWeight: 'bold' }}
            >
              Clique aqui
            </a>{' '}
            e leia o regulamento completo.
          </Typography>
        </Centered>
      </Box>
    );
  }

  return (
    <Box style={{ height: '100%' }}>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        style={{ height: '100%' }}
      >
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          xs={12}
          sm={6}
        >
          <Box mt={30}>
            <LoginForm onSubmit={onSubmit} />
          </Box>
        </Grid>
        <Grid
          item
          xs={6}
          sm={6}
          style={{
            textAlign: 'center',
          }}
        ></Grid>
      </Grid>
      <WhatsappButton />
    </Box>
  );
}

export default Login;
