import { Box, Grid, Typography, useMediaQuery } from '@material-ui/core';
import React from 'react';
import { toast } from 'react-toastify';
import { auth } from 'services/firebase';
import ForgotPasswordForm from 'forms/ForgotPasswordForm/ForgotPasswordForm';
import Centered from 'components/Centered/Centered';
import DAF from 'images/DAF-branco.svg';

const ForgotPassword = () => {
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));

  const onSubmit = async values => {
    try {
      await auth.sendPasswordResetEmail(values.email);
      toast.success('E-mail de recuperação de senha enviado com sucesso.');
    } catch {
      toast.error(
        'Não foi possível enviar o e-mail de recuperação, tente novamente mais tarde.',
      );
    }
  };

  if (isMobile) {
    return (
      <Box
        style={{ height: '100%', backgroundColor: '#014a94', color: '#fff' }}
      >
        <Centered column>
          <Box mb={7} mt={7} textAlign={'center'}>
            <img
              style={{ width: '100%', maxWidth: '288px' }}
              src={DAF}
              alt="Logo da DAF"
            />
          </Box>
          <Typography
            style={{
              fontSize: '1.5rem',
              textAlign: 'justify',
              marginBottom: '2rem',
            }}
          >
            Prova indisponível para acesso via dispositivo móvel, conforme
            regulamento:
          </Typography>
          <Typography
            style={{
              fontSize: '1.5rem',
              textAlign: 'justify',
              marginBottom: '2rem',
            }}
          >
            &quot;O participante deverá acessar as provas através de um
            computador (desktop ou laptop). Não será possível acessar através de
            dispositivos móveis.&quot;
          </Typography>
          <Typography
            style={{
              fontSize: '1.5rem',
              textAlign: 'justify',
              marginBottom: '2rem',
            }}
          >
            <a
              href="https://firebasestorage.googleapis.com/v0/b/questionario-daf.appspot.com/o/20233006%20-%20Regulamento%20-%20Te%CC%81cnico%20do%20Ano%20DAF%202023.pdf?alt=media&token=200b4901-4223-4a24-8414-ccde23588020"
              target="_blank"
              rel="noreferrer"
              style={{ fontWeight: 'bold' }}
            >
              Clique aqui
            </a>{' '}
            e leia o regulamento completo.
          </Typography>
        </Centered>
      </Box>
    );
  }

  return (
    <Box style={{ height: '100%' }}>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        style={{ height: '100%' }}
      >
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          xs={12}
          sm={6}
        >
          <Box mt={30} style={{ width: '80%' }}>
            <ForgotPasswordForm onSubmit={onSubmit} />
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          style={{
            textAlign: 'center',
          }}
        ></Grid>
      </Grid>
    </Box>
  );
};

export default ForgotPassword;
